<template>
  <div class="container">
    <el-main>
      <!-- 主干区域 -->
      <div class="content">
        <comm-step :type="6" :percentage="100"></comm-step>
        <div class="txt">注册完成！</div>

        <div class="btnBox">
          <el-button style="margin:auto" @click="next()"> 进入系统 </el-button>
        </div>
      </div>
    </el-main>
  </div>
</template>

<script>
import commStep from "c/commStep";
import { addLog } from "r/index/register";
import axios from 'axios'
export default {
  components: { commStep },
  created() {
    this.$emit("getStatus", 2);
    this.addLog();
  },
  methods: {
    // 下一步
    next() {
      this.$router.replace({
        name: "examlist",
      });
    },
    // 注册完成埋点log
    async addLog() {
      const { data: res } = await addLog({ type: 7 });
      console.log(res);
    },
  },
};
</script>

<style scoped lang="scss">
#container {
  position: relative;
}
.container {
  width: 100%;
  height: auto;
  margin: 0 auto;
  .el-main {
    max-width: 1300px;
    margin: 6px auto;
    padding: 20px 50px;
    .content {
      background-color: #ffffff;
      padding: 60px;
      .txt {
        font-size: 20px;
        text-align: center;
        margin: 100px 0;
      }
      .btnBox {
        text-align: center;
        .el-button {
          background-color: #590f77;
          color: #fff;
          width: 240px;
          height: 50px;
          line-height: 50px;
          padding: 0;
        }
      }
    }
  }
}
</style>
